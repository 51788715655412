
import axios from "axios";
import store from '@/store';
import router from '@/router/index';
let requests = axios.create({
  // baseURL: "https://app.cargosoon.com/api",
  baseURL: "https://testapp.co-logistics.com/api",
  timeout:25000,
});

requests.interceptors.request.use((config) => {
  //需要携带token带给服务器
  if(store.state.user.token){
    config.headers.token = store.state.user.token;
  }
  return config;
});

requests.interceptors.response.use(
  (res) => {
    // if(res.data.msg=='请先登录'){
    //   store.dispatch('userLogout')
    //   router.push('/login')
    // }
    if(res.data.msg=='token失效' || res.data.msg=='账号异常'){
      store.dispatch('userLogout')
      router.push('/login')
    }
    return res.data;
  },
  (err) => {
   
  }
);
export default requests;
